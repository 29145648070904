<template>
  <div class="p-6 loading-table" v-if="loading">
    <spinner-color />
  </div>
  <div v-else>
    <data-table-order
      :data="this.$store.state.dataList.orders"
      :statusForAdvancedSearch="statusForAdvancedSearch"
      :header="header"
      :body="body"
      :pageName="pageName"
      :titlePage="titlePage"
      :advancedSearchData="advancedSearchData"
    />
  </div>
</template>

<script>
import DataTableOrder from "../components/table-components/DataTableOrder.vue";
import SpinnerColor from "@/views/components/table-components/SpinnerColor2.vue";
import loadingTable from "@/mixins/loadingTable.js";
import allStatus from "@/views/status/orderStatus";

export default {
  mixins: [loadingTable],
  components: {
    DataTableOrder,
    SpinnerColor,
  },
  data() {
    return {
      header: [
        { header: "dateOfRegistration", key: "created_at" },
        { header: "code", key: "code" },
        { header: "sender", key: "second_client" },
        { header: "receiver", key: "receiver" },
        { header: "government", key: "gov_name" },
        { header: "area", key: "area" },
        { header: "collectedAmount", key: "amount" },
        { header: "actions", key: "actions" },
      ],
      body: [
        "created_at",
        "code",
        "second_client",
        "receiver",
        "gov_name",
        "area",
        "amount",
      ],
      advancedSearchData: [
        {
          label: "dateOfRegistration",
          nameToEndpoint: "dateOfRegistration",
          max: "",
          type: "date",
        },
        { label: "code", nameToEndpoint: "code", max: "", type: "text" },
        { label: "sender", nameToEndpoint: "sender", max: 15, type: "text" },
        {
          label: "receiver",
          nameToEndpoint: "receiver",
          max: 15,
          type: "text",
        },
        { label: "area", nameToEndpoint: "area", max: 13, type: "text" },
        {
          label: "collectedAmount",
          nameToEndpoint: "collectedAmount",
          max: 15,
          type: "text",
        },
        {
          label: "invoiceNumber",
          nameToEndpoint: "invoiceNumber",
          max: 50,
          type: "text",
        },
      ],
      pageName: "order/sent",
      statusForAdvancedSearch: allStatus.sent,
      titlePage: "sentForShip",
    };
  },
  async created() {
    this.credentials.page = "order/sent";
    this.fetchData(this.credentials);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_loadingTable.scss";
</style>
